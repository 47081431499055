@import "scss/lib/index.scss";

.common-header {
    width: 100%;
    border-bottom: 1px solid $color-neutral-300;
    min-height: 60px;
    height: 50px;

    display: flex;

    > .left {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 16px;
        margin-right: 14px;

        font-weight: bold;
        color: $color-neutral-900;
        font-size: 16px;

        overflow: hidden;
    }

    > .right {
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: 20px;
        font-size: 14px;
    }
}
