@import "scss/lib/index.scss";

.components-schema-tree {
    height: 100%;
    display: flex;
    flex-direction: column;

    > .title-section {
        padding: 8px 0 8px 16px;
        display: flex;
        align-items: center;
        font-weight: bold;
        border-bottom: 1px solid $color-neutral-300;

        > .refresh-btn {
            margin-left: auto;
        }
    }

    > .search-input {
        margin: 16px 16px 0;
    }

    .schema-tree {
        .item {
            &.top-level {
                font-weight: bold;
            }
        }

        > .empty-state {
            padding: 20px;
            text-align: center;
            color: $color-neutral-700;

            > .title {
                font-weight: bold;
                margin-bottom: 5px;
            }
        }

        > .error-state {
            padding: 20px;
            text-align: center;
            color: $color-neutral-700;
            word-wrap: break-word;

            > .title {
                font-weight: bold;
                margin-bottom: 5px;
            }
        }

        > .loading-container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: 700;
            text-align: center;
            font-size: 17px;

            .components-loading {
                margin-bottom: 10px;
            }
        }
    }
}

.column-tooltip {
    display: flex;
    flex-direction: column;

    > .column-name {
        @include text-monospaced;
        font-weight: bold;
    }

    > .column-icon-texts {
        margin-top: 5px;
        color: $color-neutral-300;

        > .column-icon-text {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}
