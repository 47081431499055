@import "scss/lib/index.scss";

.explain-page-explain {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $color-neutral-0;

    .header-title {
        max-width: 66.6%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .panes {
        display: flex;
        flex-direction: row;
        height: 100%;
        overflow-y: hidden;

        .left-pane {
            display: flex;
            flex-direction: column;
            flex: 1;

            > .warning-section {
                border-bottom: 1px solid $color-neutral-300;
                font-size: 14px;
                padding: 14px 15px;

                .warning-section-title {
                    display: flex;
                    align-items: center;
                }

                .warning-item {
                    display: flex;
                    margin-bottom: 6px;

                    > .warning-icon {
                        margin-top: 2px;
                    }
                }
            }

            .viewport {
                width: 100%;
                height: 100%;
                background-color: $color-neutral-100;

                .tabs {
                    position: absolute;
                    top: 24px;
                    left: 24px;
                    width: 360px;

                    box-shadow: 0px 1px 4px rgba(60, 60, 60, 0.15);
                }
            }
        }

        .right-pane {
            border-left: 1px solid #e5e5e5;
        }
    }

    .upload-button {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
