@import "scss/lib/index.scss";

.monitor-mv-column-picker {
    height: 400px;
    width: 230px;

    .categories {
        border-bottom: 1px solid $color-neutral-200;
        padding-bottom: 8px;
        margin-bottom: 5px;

        .name {
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .group-header {
        text-transform: uppercase;
        color: $color-neutral-700;
        margin-bottom: 5px;
    }

    .picker-option {
        display: flex;
        align-items: center;
        font-size: 14px;

        &:not(.disabled) {
            cursor: pointer;
        }

        &.disabled {
            .name {
                color: $color-neutral-700;
            }
        }

        &:not(:last-child) {
            padding: 3px 0;
        }

        .picker-icon {
            color: $color-purple-900;
            margin: 3px 10px 3px 2px;
            width: 1.25em;
        }

        .name {
            padding: 3px 4px;
            border-radius: 3px;
            color: $color-neutral-999;
        }

        .info-icon-overlay {
            display: flex;
            align-items: center;
            margin-left: 5px;

            .info-icon {
                display: flex;
                align-items: center;
            }
        }
    }
}
