@keyframes moving-gradient {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.components-loading-bar {
    height: 10px;

    border-radius: 8px;
    background: linear-gradient(to right, #d0d4d7, #e3e5e7, #d0d4d7);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}
