@import "scss/lib/index.scss";

.schema-header {
    .database-select {
        max-width: 25em;
        font-size: 14px;
        font-weight: 700;
    }

    .actions {
        .run-cancel-btn {
            .shortcut {
                @include text-monospaced;
            }
        }
    }
}
