@import "scss/lib/index.scss";

.components-nested-tree {
    $caret-width: 10px; /* shared with the JavaScript! */
    $caret-margin: 5px; /* shared with the JavaScript! */

    $icon-width: 15px; /* shared with the JavaScript! */
    $icon-margin: 5px;

    $item-height: 30px; /* shared with the JavaScript! */

    background-color: white;
    height: 100%;
    padding-top: 8px;
    font-size: 14px;

    overflow: hidden;

    @mixin base-action {
        margin-left: auto;
        display: none;
    }

    @mixin visible-action {
        display: block;
    }

    @mixin normal-caret {
        width: $caret-width;
        margin-right: $caret-margin;
        transition: transform 200ms;
    }

    @mixin expanded-caret {
        transform: rotate(90deg);
    }

    .tree-inner {
        position: relative;
        outline: none; /* suppress outline caused by tabIndex */

        .items {
            position: relative;

            .item {
                &.expanded {
                    .caret {
                        @include expanded-caret;
                    }
                }

                display: flex;
                align-items: center;

                @include text-monospaced;
                color: $color-neutral-800;

                height: $item-height;
                padding-right: 24px;

                .caret-container {
                    flex: none;

                    height: 100%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .caret {
                        @include normal-caret;
                        color: $color-neutral-700;
                    }
                }

                .icon {
                    flex: none;

                    margin-right: $icon-margin;
                    width: $icon-width;
                    color: $color-neutral-700;
                }

                .name {
                    text-align: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .actions {
                    @include base-action;
                }

                &.active {
                    color: $color-purple-900;

                    .caret,
                    .icon {
                        color: $color-purple-900;
                    }
                }

                &.clickable {
                    cursor: pointer;

                    &:hover {
                        background-color: $color-indigo-100;
                        color: $color-purple-900;

                        .icon {
                            color: $color-purple-900;
                        }

                        .caret {
                            color: $color-purple-900;
                        }

                        .actions {
                            @include visible-action;
                        }
                    }
                }
            }
        }
    }
}
