@import "scss/lib/index.scss";

.cluster-layout-sidebar-footer {
    .sidebar-footer-dropdown-btn {
        font-size: 21px;
        color: $color-neutral-0;
        border-color: transparent;

        &:hover,
        &.active {
            background-color: $sidebar-hover-bg;
            border-color: $sidebar-active-bg;
        }
    }
}
