@import "scss/lib/index.scss";

.layout-main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;

    > .flex-content {
        flex: 1;
        display: flex;
        position: relative;
        z-index: $zindex-base;

        /* Required on Firefox https://stackoverflow.com/a/28639686 */
        min-height: 0;

        .layout-main-content {
            flex: 1 1 0%;
            display: flex;
            flex-direction: column;
            position: relative;

            /* https://stackoverflow.com/questions/31967019/max-width-doesnt-work-in-flexbox */
            max-width: 100%;
            min-width: 0;

            .layout-main-page {
                flex: 1;
                overflow: hidden;
                position: relative;
            }
        }
    }
}
