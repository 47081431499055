@import "scss/lib/index.scss";

.delete-cluster-form {
    .form-error {
        color: $error-text;
        margin-bottom: 10px;
    }

    .agreement-field {
        text-align: left;
        margin-top: 20px;
    }

    .cluster-profile {
        font-weight: bold;
    }
}
