@import "scss/lib/index.scss";

.add-new-cluster-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 50px;

        h1 {
            @include text-title;
            margin-bottom: 20px;
        }

        h2 {
            @include text-body-2;
            color: $color-neutral-700;
            text-align: center;

            div {
                margin-bottom: 5px;
            }
        }
    }

    .form-wrapper {
        width: 65%;
        max-width: 500px;
    }
}
