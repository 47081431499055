@import "scss/lib/index.scss";

.zoom-overlay {
    position: absolute;
    right: 24px;
    bottom: 24px;

    width: 36px;
    height: 78px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .zoom-button {
        width: 36px;
        height: 36px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: $color-neutral-0;

        box-shadow: 0px 1px 4px rgba(60, 60, 60, 0.15);
    }
}
