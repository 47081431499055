@import "scss/lib/index.scss";

.components-tabs {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    $tab-border-color: $color-neutral-300;
    $tab-active-border-color: $color-purple-900;
    $tab-active-color: $color-neutral-900;
    $tab-inactive-color: $color-neutral-800;
    $nested-tab-active-bg-color: white;
    $nested-tab-inactive-bg-color: $color-neutral-100;

    .tab-bar {
        display: flex;
        max-height: 40px;
        min-height: 40px;

        .tab-title {
            display: flex;
            align-items: center;
            height: 100%;

            font-size: 14px;
            font-weight: 700;
            color: $tab-inactive-color;

            overflow: hidden;

            &:hover {
                cursor: pointer;
            }

            &.active {
                color: $tab-active-color;
            }
        }

        > .rest {
            /* must cover all of remaining space in tab-bar so nested can give
             * it a bottom border */
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            height: 100%;

            > .new-tab-button {
                flex: 0 0 30px;
                margin-left: 5px;

                width: 30px;
                height: 30px;
                border-radius: 5px;

                display: flex;
                justify-content: center;
                align-items: center;

                color: $color-neutral-700;
                cursor: pointer;

                &:hover {
                    background-color: $color-neutral-300;
                }

                &.disabled {
                    opacity: 0.25;
                    cursor: default;

                    /* can't disable pointer-events because we still want a tooltip */
                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            > .right {
                margin-left: auto;
            }
        }

        &.light {
            border-bottom: 1px solid $tab-border-color;

            .tab-title {
                margin: 0 12px;
                padding: 0 4px;
                border-top: 2px solid transparent;
                border-bottom: 2px solid transparent;

                &.active {
                    border-bottom: 2px solid $tab-active-border-color;
                }
            }

            &.border-top {
                border-top: 1px solid $tab-border-color;
            }
        }

        &.nested {
            .tab-title {
                border-top: 1px solid $tab-border-color;
                border-right: 1px solid $tab-border-color;
                border-bottom: 1px solid $tab-border-color;
                background-color: $nested-tab-inactive-bg-color;
                padding: 0 16px;

                &:first-child {
                    border-left: 1px solid $tab-border-color;
                }

                &.active {
                    position: relative;
                    background-color: $nested-tab-active-bg-color;

                    // omit bottom border so tab looks continuous with tab
                    // contents
                    border-bottom-width: 0;
                    padding-bottom: 1px;

                    // omit top border so it does not clash with the fake
                    // border below
                    border-top-width: 0;
                    padding-top: 1px;

                    // fake borders over nested active tabs
                    // colored border on top
                    &:before {
                        position: absolute;
                        content: "";
                        width: calc(100% + 2px);
                        top: 0px;
                        left: -1px;
                        border-top: 2px solid $tab-active-border-color;
                    }
                }
            }

            > .rest {
                border-bottom: 1px solid $tab-border-color;
            }

            &.border-top > .rest {
                border-top: 1px solid $tab-border-color;
            }
        }
    }
}

.components-tab-badge {
    padding: 1px 8px;
    border-radius: 6px;
    background-color: $color-neutral-300;

    color: $color-neutral-800;
    font-size: 13px;
    font-weight: 700;
    text-align: center;

    &.active {
        background-color: $color-purple-900;
        color: white;
    }

    &.right-margin {
        margin-right: 8px;
    }

    &.left-margin {
        margin-left: 8px;
    }
}

.components-tab-title {
    display: flex;
    overflow: hidden;

    &.fixed-width {
        width: 150px;
    }

    > .right {
        margin-left: auto;
        display: flex;
        align-items: center;

        > .loading {
            margin-left: 8px;
            align-self: center;
        }

        > .close-button {
            margin-left: 8px;

            width: 20px;
            height: 20px;
            border-radius: 5px;

            display: flex;
            justify-content: center;
            align-items: center;

            color: $color-neutral-700;
            cursor: pointer;

            &:hover {
                background-color: $color-neutral-300;
            }

            &.disabled {
                opacity: 0.25;
                /* don't change the pointer, since the close button will just
                 * be part of the tab and clicking it will switch to the tab */

                /* can't disable pointer-events because we still want a tooltip */
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}
