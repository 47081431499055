@import "scss/lib/index.scss";

.components-toast-wrapper {
    display: flex;
    align-items: center;
    position: absolute;

    top: 30px;
    left: 0;
    right: 0;

    pointer-events: none;

    .components-toast {
        margin: 0 auto;
        z-index: $zindex-toast;

        padding: 12px 20px;
        background: $success-background;
        border-left: 4px solid $success-text;
        border-radius: 4px;
        box-shadow: $box-shadow-small-pos-y;

        transition: opacity 0.5s ease-out;

        font-weight: bold;
        font-size: 14px;
        color: $color-text-2;

        pointer-events: auto;

        .toast-icon {
            font-size: 16px;
            color: $success-text;
            margin-right: 12px;
        }
    }
}
