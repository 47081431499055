@import "scss/lib/index.scss";

.schema-page-tables-main {
    height: 100%;
    display: flex;
    flex-direction: column;

    .title-text {
        display: flex;
        justify-content: center;
    }

    .schema-table {
        flex: 1;
    }

    .zero-of-entity {
        padding: 20px;
    }

    > .errors-card {
        margin: 16px 24px;

        .errors-card-title {
            display: flex;
            align-items: center;

            &.error-offline {
                color: $error-text;
            }

            > .database-status {
                display: inline-block;

                margin-left: 5px;
            }
        }

        .database-states-help-link {
            display: block;
            margin-top: 6px;
        }

        .impacted-message {
            margin-top: 6px;
        }

        + .database-info-card {
            margin-top: 0px;
        }
    }

    > .database-info-card {
        margin: 16px 24px;
        padding: 16px;
    }
}
