@import "scss/lib/index.scss";

.events-page {
    @include full-page;

    .last-updated {
        color: $color-neutral-700;
        margin-right: 15px;
    }

    .cell.eventDetails {
        @include text-monospaced;
    }
}

.event-details-tooltip {
    margin: 0;
}
