@import "scss/lib/index.scss";

.bottom-panel-top-bar {
    display: flex;
    height: 100%;
    align-items: center;

    .console-icon {
        margin-left: 10px;
        color: $color-neutral-800;
    }
}
