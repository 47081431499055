@import "scss/lib/index.scss";

.common-general-error {
    .icon-wrapper {
        display: flex;
        flex-direction: column;
        color: $error-text;

        .caption {
            @include text-button;
            text-align: center;
            margin-top: 10px;
        }
    }

    .notes {
        display: flex;
        flex-direction: column;
        text-align: center;

        margin-top: 40px;
        @include text-body-2;
        color: $color-neutral-700;
        max-width: 40%;

        .error-message {
            text-align: left;
            margin-top: 20px;
            @include text-monospaced;
        }
    }
}
