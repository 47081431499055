@import "scss/lib/index.scss";

.common-notification-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

    &.critical {
        background: $error-text;
    }

    &.info {
        background: $info-text;
    }

    &.warning {
        background: $alert-text;
    }

    .notification-bar-inner {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        font-size: 14px;
        line-height: 22px;

        a {
            color: white;
            text-decoration: underline;
        }
    }

    > .icon-close {
        cursor: pointer;
        padding: 8px 12px;
    }
}
