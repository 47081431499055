@import "scss/lib/index.scss";

.collapsable-form {
    text-align: left;
    counter-reset: section-counter;

    .form-error {
        color: $error-text;
        text-align: center;
        font-size: 17px;
        margin-bottom: 20px;

        .icon-error {
            margin-right: 10px;
        }
    }

    .collapsable-section {
        padding: 25px;
        margin-bottom: 20px;
        outline: none;

        border-top: 1px solid $color-neutral-300;

        .info {
            cursor: pointer;
            margin-bottom: 20px;
            display: flex;

            .section-index {
                font-size: 18px;
                font-weight: 900;
                padding: 14px 20px;
                text-align: center;
                color: white;
                height: 48px;

                border-radius: 50%;
                background-color: $color-purple-900;
            }

            .info-text {
                flex: 1;
                margin-left: 15px;

                .title {
                    @include text-subheader;
                    color: $color-neutral-900;

                    .section-error-icon {
                        margin-left: 10px;
                        color: $error-text;
                    }
                }

                .description {
                    @include text-body;
                    color: $color-neutral-700;
                }

                .section-error {
                    margin-top: 10px;
                    color: $error-text;
                }

                .field-values {
                    margin-top: 25px;
                    color: $color-neutral-700;

                    .error {
                        color: $error-text;
                    }
                }
            }
        }
    }
}
