@import "scss/lib/index.scss";

.explain-sidebar {
    // this needs to match with section.scss
    $hor-padding: 15px;
    $ver-padding: 15px;

    height: 100%;
    font-size: 14px;

    min-width: 275px;
    max-width: 275px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    .help-icon {
        color: $color-neutral-700;
    }

    .header {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .global-details {
        border-top: 1px solid $color-neutral-300;

        .header {
            padding: $ver-padding $hor-padding 0 $hor-padding;
        }

        .operator-list {
            .header {
                display: flex;
                justify-content: space-between;
                padding: 10px 0px 0px 0px;

                font-size: 14px;
                color: $color-neutral-999;
                font-weight: bold;
                text-decoration: underline;
            }

            .operator-listing {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                padding: 5px 0px;

                cursor: pointer;

                .executor {
                    color: $color-neutral-700;
                    max-width: 50%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .metric {
                    max-width: 50%;
                    overflow: hidden;
                }

                &:hover {
                    background-color: $color-indigo-100;
                }
            }
        }
    }

    .section-element-rows-container {
        .section-element-row {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            padding: 5px 0;

            $middle-spacing: 16px;

            > .label {
                max-width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                margin-right: $middle-spacing / 2;

                color: $color-neutral-700;
            }

            > .value {
                max-width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                margin-left: $middle-spacing / 2;
            }
        }
    }
}

.sidebar-help-tooltip {
    max-width: 250px;
}
