@import "scss/lib/index.scss";

@mixin spinner-classes($animationName, $size, $strokeWidth) {
    .loading-circle {
        stroke-width: $strokeWidth;
    }

    .loading-circle-stroke {
        animation: $animationName 5s linear infinite;
        stroke-dasharray: ($size * 3.14);
    }
}

@mixin spinner-keyframes($size) {
    0% {
        transform: rotate(0);
        stroke-dashoffset: ($size * 4);
    }
    50% {
        transform: rotate(720deg);
        stroke-dashoffset: ($size * 5.3);
    }
    100% {
        transform: rotate(1080deg);
        stroke-dashoffset: ($size * 4);
    }
}

.components-loading {
    .loading-circle {
        stroke-linecap: round;
        fill: transparent;
    }

    .loading-circle-stroke {
        transform-origin: 50% 50% 0;
        stroke-linecap: round;
        fill: transparent;
    }

    &.large {
        @include spinner-classes(components-loading-large, 80px, 3px);

        @keyframes components-loading-large {
            @include spinner-keyframes(80px);
        }
    }

    &.medium {
        @include spinner-classes(components-loading-large, 40px, 2px);

        @keyframes components-loading-large {
            @include spinner-keyframes(40px);
        }
    }

    &.small {
        @include spinner-classes(components-loading-small, 16px, 2px);

        @keyframes components-loading-small {
            @include spinner-keyframes(16px);
        }
    }

    &.outlineOnly,
    &.small {
        .loading-logo {
            display: none;
        }
    }

    &.primary .loading-circle-stroke {
        stroke: $color-purple-900;
    }
    &.success .loading-circle-stroke {
        stroke: $success-text;
    }
    &.warning .loading-circle-stroke {
        stroke: $alert-text;
    }
    &.error .loading-circle-stroke {
        stroke: $error-text;
    }

    &.right-margin {
        margin-right: 5px;
    }
}
