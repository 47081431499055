@import "scss/lib/index.scss";

.components-common-ratio-metric-cell {
    width: 100%;

    > .cell-bottom-label {
        font-size: 12px;
        line-height: 16px;
        color: $color-neutral-700;
    }

    // Label on top of the bar.
    // Read component comments. If the label position is north we assume the
    // component is being renderer in a table.
    &.top {
        .cell-label {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            color: $color-neutral-800;
        }

        > .cell-bottom-label {
            margin-top: 4px;
        }
    }

    &.critical {
        .cell-label {
            color: $error-text;
        }
    }
}
