@import "scss/lib/index.scss";

.license-usage-help-tip {
    display: inline;
}

.license-usage {
    display: inline;

    &.warning {
        color: $alert-text;
    }
}
