@import "scss/lib/index.scss";

.sql-editor {
    .selected-query {
        background-color: $color-indigo-100;
    }

    // Forces sql editor MonacoEditor custom scrollbar to use the studio scrollbar z-index
    .slider {
        z-index: $zindex-scrollbar;
    }
}

/* We completely hide the Monaco accessibility container to avoid visual issues in Firefox. */
.monaco-aria-container {
    display: none;
}
