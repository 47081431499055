@import "scss/lib/index.scss";

.common-dashboard-card-metrics {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 22px;
    row-gap: 22px;

    $block-warning-color: $alert-background;
    $block-error-color: $error-background;

    > .block {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 96px;

        background-color: $color-neutral-200;
        border-radius: 4px;

        text-align: center;
        padding: 14px;

        > .title {
            font-size: 14px;
            font-weight: 700;
            color: $color-neutral-700;
            text-transform: uppercase;
            letter-spacing: 0.04em;
        }

        > .label {
            font-size: 16px;
            font-weight: 700;
            color: $color-neutral-900;

            margin-top: 5px;
        }

        &.full-width {
            grid-column: 1 / -1;
        }

        &.warning {
            background-color: $block-warning-color;
            border-color: $block-warning-color;

            > .label {
                color: $alert-text;
            }
        }

        &.error {
            background-color: $block-error-color;
            border-color: $block-error-color;

            > .label {
                color: $error-text;
            }
        }
    }
}

.common-dashboard-card-container {
    display: flex;

    .dashboard-card {
        flex: 1;
        position: relative;
        padding: 0px;

        font-size: 12px;
        box-sizing: border-box;
        box-shadow: none;

        &:hover {
            .card-footer {
                > .docs-link {
                    visibility: visible;
                }
            }
        }

        .dashboard-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 12px 16px;
            border-bottom: 1px solid $color-neutral-300;

            .card-title {
                font-size: 16px;
                font-weight: 700;
                color: $color-neutral-900;
            }

            .view-all {
                font-size: 14px;
                display: flex;
                align-items: center;
            }
        }

        .card-footer {
            position: absolute;
            bottom: 8px;
            left: 16px;

            > .docs-link {
                visibility: hidden;
            }
        }

        &.success {
            display: flex;
            flex-direction: column;
            padding-bottom: 32px;

            > .content {
                overflow-y: auto;
                padding: 18px 18px 0px;
            }
        }

        &.empty {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            padding: 24px;

            > .empty-container {
                display: flex;
                flex-direction: column;
                align-items: center;

                > .title {
                    font-size: 18px;
                    text-align: center;
                    font-weight: 900;
                    color: $color-neutral-800;
                    margin-top: 16px;
                }

                > .description {
                    font-size: 15px;
                    text-align: center;
                    color: $color-neutral-700;
                    margin-top: 5px;
                }

                > .help-link {
                    font-size: 15px;
                    margin-top: 5px;
                }
            }
        }

        &.loading {
            display: flex;
            flex-direction: column;

            text-align: center;
            font-size: 17px;

            > .loading-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;

                font-weight: 700;
                padding: 24px;

                .components-loading {
                    margin-top: 0;
                    margin-bottom: 10px;
                }
            }
        }

        &.error {
            display: flex;
            flex-direction: column;

            > .error-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                text-align: center;
                padding: 24px;

                > .icon {
                    color: $color-neutral-700;
                }

                > .title {
                    color: $color-neutral-800;
                    font-weight: 700;
                    font-size: 20px;

                    margin-top: 20px;
                }

                > .message-container {
                    color: $color-neutral-700;
                    font-size: 14px;
                    line-height: 150%;

                    margin-top: 16px;
                    overflow-y: auto;

                    > .message {
                        @include text-monospaced;
                        font-size: 13px;
                        margin-top: 10px;
                        padding: 0 8px;
                    }
                }
            }
        }
    }
}
