@import "scss/lib/index.scss";

.components-viewport {
    position: relative;
    overflow: hidden;
    cursor: grab;

    &.dragging {
        cursor: grabbing;
    }

    .renderer {
        position: absolute;
    }
}
