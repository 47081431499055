/* Global styles for the application */

@import "vendor/normalize";

@import "lib/index.scss";
@import "typography.scss";

* {
    box-sizing: border-box;
}

html,
body,
#react-root {
    height: 100%;
    margin: 0;
}
