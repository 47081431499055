@import "scss/lib/index.scss";

@keyframes move {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 10px 10px;
    }
}

.mv-record-modal {
    .mv-record-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .title {
            @include text-title;
        }

        > .inner {
            width: 80%;
            margin-top: 20px;

            > .timer {
                font-weight: bold;
                font-size: 20px;
                color: $color-purple-900;
                margin-bottom: 10px;
            }

            > .text {
                line-height: 170%;
                color: $color-neutral-700;
            }

            > .loading-spinner {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            > .cancel-btn {
                margin-top: 20px;
            }
        }
    }
}
