@import "scss/lib/index.scss";

@keyframes slideFromAbove {
    0% {
        transform: translateY(-10%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.connect-to-cluster-form {
    position: relative;

    animation: 200ms ease-out slideFromAbove;

    .form-message {
        margin-top: 20px;
    }

    .form-content {
        margin-top: 30px;
        position: relative;

        .form-loading {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }

        &.submitting {
            /* For some reason, if we only use the `visibility: hidden` rule,
             * there is a lag between when we render the "submitting" class and
             * when the element becomes invisible, so we set visibility and
             * opacity. */
            .form-controls {
                /* For some reason, if we only use the `visibility: hidden` rule,
                                * there is a lag between when we render the "submitting" class and
                                * when the element becomes invisible, so we set visibility and
                                * opacity. */
                visibility: hidden;
                opacity: 0;
            }
        }

        input {
            width: 100%;
        }

        .form-error {
            color: $error-text;
        }

        .submit-btn {
            margin-top: 10px;
        }
    }
}
