@import "scss/lib/index.scss";

.monitor-mv-header-wrapper {
    .filter-bar {
        background-color: $color-neutral-100;

        .header-filter {
            display: inline-block;
            padding: 16px 0 16px 16px;
            max-width: 100%;

            .inner {
                background-color: $color-neutral-700;
                color: $color-neutral-0;
                font-size: 14px;
                border-radius: 3px;
                padding: 10px 15px;
                display: flex;
                align-items: center;

                .description {
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .disable-btn {
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    .monitor-mv-header {
        .right {
            /* perfect alignment with the Category Selector button */
            padding-right: 4px;
        }

        .actions {
            color: $color-neutral-700;

            > .last-updated {
                color: $color-neutral-700;
                margin-right: 15px;
            }

            .load-save-btn {
                margin-left: 10px;
            }
        }
    }

    .monitor-mv-tabs {
        display: flex;
        border-bottom: 1px solid $color-neutral-300;

        .header-tab {
            display: flex;
            align-items: center;

            padding: 0 4px;
            margin: 0 12px;
            height: 40px;
            border-bottom: 2px solid transparent;

            cursor: pointer;
            font-weight: bold;
            font-size: 14px;
            transition: border-bottom 150ms;

            &.active {
                border-bottom: 2px solid $color-purple-900;
                color: $color-neutral-900;
            }
        }
    }
}
