@import "scss/lib/index.scss";

.schema-page {
    @include full-page;
}

.schema-view {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: hidden;
    background-color: $color-neutral-0;
}
