@import "lib/index.scss";

html,
body {
    font-family: "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    @include text-body;
    color: $color-text-2;
}

h1,
h2,
h3,
h4 {
    margin: 0;
    padding: 0;
}

h1 {
    @include text-title;

    &.headline {
        @include text-headline;
    }
}
h2 {
    @include text-subheader;
}
h3 {
    @include text-subheader-small;
}
h4 {
    @include text-body-2;
}

p {
    @include text-body;
    margin: 0.6em 0;
}

a {
    color: $color-indigo-600;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.text-label {
    @include text-label;
}

.text-caption {
    @include text-caption;
}

code,
pre {
    @include text-monospaced;
}
