@import "scss/lib/index.scss";

.node-badge {
    padding: 2px 6px;

    font-weight: 700;
    border-radius: 4px;

    &.leaf {
        background-color: $color-neutral-200;
        border: 1px solid $color-neutral-700;
    }

    &.aggregator {
        background-color: $color-indigo-100;
        border: 1px solid $color-indigo-600;
    }

    &.master {
        background-color: $color-purple-200;
        border: 1px solid $color-purple-900;
    }
}
