@import "scss/lib/index.scss";

.loading-error-error {
    font-weight: bold;
    color: $error-text;
}

.loading-bar-cell {
    height: 100%;
    display: flex;
    align-items: center;
}
