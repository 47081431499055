@import "scss/lib/index.scss";

.bottom-panel-console {
    width: 100%;
    height: 100%;
    background: $color-neutral-900;
    font-size: 18px;
    color: $color-neutral-0;

    display: flex;
    flex-direction: column;

    .scrollback {
        white-space: pre-wrap;
        margin: 0;
        overflow: visible;
    }

    .terminal-input-block {
        padding: 5px 10px 5px 10px;
        display: flex;

        .angle-icon {
            /* perfect alignment */
            margin-top: 1px;
            margin-right: 8px;
        }

        .terminal-input {
            appearance: none;

            border: none;
            background: none;
            outline: none;

            width: 100%;
            color: $color-neutral-0;

            font-family: Inconsolata, monospace;
        }

        &.disabled {
            color: $color-neutral-700;

            .terminal-input {
                color: transparent;
                text-shadow: 0 0 0 transparent;
            }
        }
    }
}
