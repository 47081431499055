@import "scss/lib/index.scss";

.results-table-header-outer {
    width: 100%;
    min-height: 46px;
    background-color: $color-neutral-100;

    .results-table-header {
        height: 100%;

        display: flex;
        align-items: center;
        padding: 0 15px;

        border-bottom: 1px solid $color-neutral-300;
        font-size: 14px;

        .right {
            margin-left: auto;
            display: flex;
            align-items: center;

            .query-time {
                flex: none;
                margin-right: 5px;
                white-space: nowrap;
            }

            .query-row-count {
                flex: none;
                margin-right: 5px;
            }
        }
    }
}
