@import "scss/lib/index.scss";

.components-radio-button {
    $color-active: $color-purple-900;

    display: block;
    position: relative;
    user-select: none;
    font-size: 16px;

    & > * {
        cursor: pointer;
    }

    &.disabled {
        & > * {
            cursor: not-allowed;
        }

        opacity: 0.4;
    }

    &.checked {
        .checkmark {
            border: $color-active solid 5px;
            background-color: $color-neutral-0;
        }
    }

    .label {
        display: inline-block;
        font-size: 14px;
        color: $color-text-1;
    }

    // hide default input
    .input {
        position: absolute;
        opacity: 0;
        width: 0px;
        height: 0px;
    }

    .text {
        padding-left: 24px;
    }

    // add custom input
    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        border: $color-neutral-700 solid 1px;
        border-radius: 50%;
        background-color: $color-neutral-100;
    }
}
