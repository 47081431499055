@import "scss/lib/index.scss";

.components-input {
    input,
    textarea {
        width: 100%;
        box-sizing: border-box;
        background-color: rgba(189, 189, 189, 0.13);
        border-radius: 4px;
        border: 2px solid transparent;
        padding: 8px;
        outline: none;

        font-size: 18px;
        line-height: 20px;

        transition: all 0.15s ease-in-out 0s;

        @include placeholder {
            color: $color-neutral-500;
        }

        &:hover {
            background: rgba(189, 189, 189, 0.2);
        }

        &:focus,
        &:active {
            background: $color-neutral-0;
            border-color: $color-purple-600;
        }
    }

    &.small {
        input,
        textarea {
            font-size: 16px;
            line-height: 18px;
        }
    }

    &.with-icon {
        position: relative;

        > .icon {
            position: absolute;
            left: 11px;
            top: 13px;
            color: $color-neutral-500;
        }

        > input {
            padding-left: 29px;
        }
    }

    &.touched {
        &.valid {
            input:not(:placeholder-shown),
            textarea:not(:placeholder-shown) {
                border-color: $success-text;
            }
        }

        &.invalid input {
            border-color: $error-text;
        }
    }
}
