.host-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    > .host-info-card {
        margin: 24px;
        padding: 16px;

        .network-io {
            width: 100%;
            display: flex;
            flex-direction: column;

            > .network-io-entry {
                display: flex;
                justify-content: space-between;
            }
        }

        .disk-io {
            width: 100%;
            display: flex;
            flex-direction: column;

            > .disk-io-entry {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    > .host-nodes-table {
        border-top: 1px solid rgba(19, 27, 67, 0.3);
    }

    .inline-cell-bar {
        width: 120px;
    }
}
