.node-badges-cell {
    height: 100%;
    display: flex;
    align-items: center;

    > .badge {
        display: inline-block;
        margin-right: 4px;
    }
}

.host-address-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    line-height: 20px;
    max-height: 40px;
}
