.cluster-profile-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: white;

    min-width: 72px;
    height: 72px;

    &.sm {
        min-width: 36px;
        max-width: 36px;
        height: 36px;
    }

    &.xs {
        min-width: 26px;
        max-width: 26px;
        height: 26px;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.cluster-profile-tooltip-text {
    max-width: 310px;

    .cluster-profile-tooltip-title {
        font-weight: 700;
    }

    .cluster-profile-tooltip-description {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
