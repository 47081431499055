@import "scss/lib/index.scss";

.schema-content {
    display: flex;
    flex-direction: column;
    flex: 1;

    width: 100%;
    height: 100%;

    .top-content {
        padding: 20px 16px;
        border-bottom: 1px solid #e0e0e0;

        > .top-content-summary {
            font-size: 18px;
        }

        > .top-content-columns {
            display: flex;

            .top-content-column {
                flex-basis: 300px;

                &:not(:first-child) {
                    margin-left: 50px;
                }
            }
        }
    }

    .main-content {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
