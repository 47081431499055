@import "scss/lib/index.scss";

.tab-log {
    padding: 10px;
    font-size: 17px;
    line-height: 150%;
    font-family: Inconsolata, monospace;

    .log-line {
        white-space: pre-wrap;

        .time {
            color: $color-neutral-500;
        }

        .error {
            color: $color-red-400;
        }
    }
}
