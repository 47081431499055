@import "scss/lib/index.scss";

.components-horizontal-bar-chart {
    width: 80%;

    .entry {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        .left {
            margin-right: 10px;

            .chart-icon {
                color: $color-neutral-700;
            }
        }

        .right {
            width: 100%;

            .top-label {
                line-height: 100%;
                font-weight: bold;
            }

            .entry-horizontal {
                width: 100%;
                display: flex;
                align-items: center;

                .inline-label {
                    font-size: 14px;
                    color: $color-neutral-700;
                    margin-left: 15px;
                    white-space: nowrap;
                }
            }
        }
    }

    // manually style the link
    > .entry-link {
        &:hover,
        &:focus {
            text-decoration: none;

            .top-label {
                text-decoration: underline;
            }
        }
    }
}
