@import "scss/lib/index.scss";

.results-placeholder {
    height: 100%;
    width: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: $color-neutral-100;
    font-size: 14px;

    color: $color-neutral-700;

    > .icon {
        flex-shrink: 0;
    }

    > .title {
        font-size: 18px;
        text-align: center;
        font-weight: 900;
        color: $color-neutral-800;
        margin-top: 20px;
    }

    > .description {
        max-width: 70%;
        font-size: 15px;
        text-align: center;
        color: $color-neutral-700;
        margin-top: 16px;

        > .shortcut {
            font-weight: bold;
        }
    }
}
