.connect-wrapper {
    .banner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: url("../../assets/images/banner.svg") top repeat-x;
        padding-top: 43px;
        text-align: center;
        height: 180px;
    }

    .children-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 88px;

        > * {
            max-width: 550px;
        }
    }
}
