@import "scss/lib/index.scss";

.schema-header {
    $spacing: 15px;

    .last-updated {
        color: $color-neutral-700;
        margin-right: $spacing;
    }

    .schema-menu {
        margin-right: $spacing;
    }
}
