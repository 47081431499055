@import "scss/lib/index.scss";

.components-alert {
    text-align: left;
    // subtract 4px from padding left to compensate for left border
    padding: 16px 16px 16px 12px;
    border-radius: 4px;
    border-left: 4px solid;

    display: flex;
    flex-direction: row;

    > .right {
        > .description {
            font-size: 14px;
            color: $color-neutral-900;
        }

        > .title {
            display: inline-block;
            font-weight: bold;
            font-size: 14px;
            color: $color-neutral-900;

            + .description {
                color: $color-neutral-800;
                margin-top: 8px;
            }
        }
    }

    &.success {
        background: $success-background;
        border-color: $success-text;

        > .left {
            > .icon {
                color: $success-text;
            }
        }
    }

    &.warning {
        background: $alert-background;
        border-color: $alert-text;

        > .left {
            > .icon {
                color: $alert-text;
            }
        }
    }

    &.error {
        background: $error-background;
        border-color: $error-text;

        > .left {
            > .icon {
                color: $error-text;
            }
        }
    }

    &.info {
        background: $info-background;
        border-color: $info-text;

        > .left {
            > .icon {
                color: $info-text;
            }
        }
    }
}
