@import "scss/lib/index.scss";

.mv-record-btn {
    > .components-icon {
        color: $color-red-900;

        &.with-right-margin {
            margin-right: 0.4em;
        }
    }
}
