@import "scss/lib/index.scss";

$modal-content-width-large: 40%;
$modal-content-width-normal: 60%;

@keyframes modal-inner-fade-in {
    from {
        top: 75px;
    }
    to {
        top: 0px;
    }
}

@keyframes modal-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

$fade-in-animation-length: 150ms;
$fade-in-animation-timing-function: ease-in-out;

.components-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(#333333, 0.9);
    z-index: $zindex-modal;

    animation-name: modal-fade-in;
    animation-duration: $fade-in-animation-length;
    animation-timing-function: $fade-in-animation-timing-function;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .modal-inner {
        position: relative;
        background-color: white;

        display: flex;
        flex-direction: column;

        width: 600px;
        margin: 60px;
        border-radius: 8px;

        animation-name: modal-inner-fade-in;
        animation-duration: $fade-in-animation-length;
        animation-timing-function: $fade-in-animation-timing-function;

        > .close-button {
            position: absolute;
            z-index: $zindex-modal + 1;

            width: 18px;
            height: 18px;
            top: -38px;
            right: -38px;

            cursor: pointer;
            color: $color-neutral-0;

            path {
                fill: $color-neutral-0;
            }

            &:hover path {
                fill: $color-neutral-700;
            }
        }

        .modal-content-inner {
            flex: 1;
            display: flex;
            flex-direction: column;

            min-height: 200px;
            overflow: auto;

            > .actions {
                width: 100%;
                padding: 40px 40px 0 40px;
                display: flex;
                justify-content: space-around;
            }

            &.with-padding {
                padding: 40px;
            }
        }

        > .close-button {
            top: -30px;
            right: -30px;
        }
    }
}
