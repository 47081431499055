@import "scss/lib/index.scss";

.node-page {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    > .node-info-card {
        margin: 24px;
        padding: 16px;
    }

    .partition-instances-empty-state {
        padding: 20px;
    }
}
