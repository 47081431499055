@import "scss/lib/index.scss";

.section-header {
    margin-top: 15px;

    font-weight: 700;
    color: $color-neutral-700;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
}
