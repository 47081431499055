@import "scss/lib/index.scss";

.cluster-switcher-menu {
    overflow-y: auto;
    max-height: 40vh;
    max-width: $sidebar-width - 14px;

    .switcher-menu-item {
        display: flex;
        align-items: center;

        .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            min-width: 26px;
            height: 26px;
        }

        .label {
            margin-left: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            /* https://css-tricks.com/flexbox-truncated-text/ */
            min-width: 0;
        }
    }
}
