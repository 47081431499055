@import "scss/lib/index.scss";

.pipelines-card-content {
    .pipeline-summary {
        display: flex;

        &:not(:first-child) {
            margin-top: 15px;
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            margin-left: 15px;
            color: $color-neutral-700;

            .pipeline-name {
                font-weight: bold;
                line-height: 100%;
            }

            .pipeline-details {
                .details {
                    display: inline;

                    &.stopped {
                        color: $alert-text;
                    }

                    &.errored {
                        color: $error-text;
                    }

                    &.canceled {
                        color: $alert-text;
                    }
                }
            }
        }
    }
}
