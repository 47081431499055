@import "scss/lib/index.scss";

.active-processes-page {
    @include full-page;

    .last-updated {
        color: $color-neutral-700;
        margin-right: 15px;
    }

    .kill-button,
    .explain-button {
        margin-right: 15px;
    }

    .kill-query-modal-body {
        .query-text {
            margin-top: 10px;
        }
    }
}
