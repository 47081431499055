@import "scss/lib/index.scss";

.cluster-health-card-content {
    .link {
        &.error {
            color: $error-text;
        }

        &.warning {
            color: $alert-text;
        }
    }

    .top-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;

        .ha-enabled {
            color: $success-text;
        }
    }

    .node-circle-list {
        @include n-columns(25, 3px);
        display: flex;
        align-items: center;

        .excess {
            font-size: 13px;
            color: $color-neutral-700;
            display: inline;
        }
    }
}
