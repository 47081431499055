@import "scss/lib/index.scss";

.monitor-mv-table {
    height: 100%;
    overflow: hidden;

    .mv-row {
        .cell {
            &.expand {
                box-shadow: none !important;

                .expand-icon {
                    color: $color-neutral-800;
                    transition: 200ms;

                    &.expanded {
                        color: $color-purple-900;
                        transform: rotate(90deg);
                    }
                }
            }

            &.activityName {
                color: $color-neutral-800;
                font-weight: bold;

                .expanded {
                    color: $color-purple-900;
                }
            }

            &.timeBreakdown {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }

        &.expandable {
            .cell {
                // All cells except for the .action cell can be clicked to expand the row.
                &:not(.action) {
                    cursor: pointer;
                }
            }

            &:hover {
                background-color: $color-indigo-100;

                .expand-icon {
                    color: $color-purple-900;
                }

                .cell.activityName {
                    color: $color-purple-900;
                }
            }
        }

        &.nested {
            background-color: $color-neutral-300 !important;
            border-bottom: 1px solid $color-neutral-300;

            .cell {
                box-shadow: none !important;
            }
        }
    }
}
