@import "scss/lib/index.scss";

.components-drag-handle {
    z-index: $zindex-drag-handle;
    position: absolute;

    &.z-index-overlay {
        z-index: $zindex-overlay;
    }

    > .bar {
        pointer-events: none;
        background-color: $color-purple-600;
        opacity: 0;
        transition: opacity 0.3s;
    }

    > .handle {
        pointer-events: none;
        position: absolute;
        background-color: $color-purple-600;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &.e,
    &.w {
        top: 0;
        width: 3px;
        height: 100%;
        cursor: ew-resize;
        padding-right: 12px;
        padding-left: 7px; /* don't want left padding to extend beyond scrollbar if scrollbar exists */

        .bar {
            width: 3px;
            height: 100%;
        }

        .handle {
            width: 2px;
            height: 24px;
            top: calc(50% - 12px);
        }
    }

    &.w {
        left: -8px;

        .handle {
            left: 2px;
        }
    }

    &.e {
        right: -11px;

        .handle {
            right: 3px;
        }
    }

    &.n,
    &.s {
        left: 0;
        height: 3px;
        width: 100%;
        cursor: ns-resize;
        padding-bottom: 12px;
        padding-top: 7px; /* don't want top padding to extend beyond scrollbar if scrollbar exists */

        .bar {
            height: 3px;
            width: 100%;
        }

        .handle {
            height: 2px;
            width: 24px;
            left: calc(50% - 12px);
        }
    }

    &.n {
        top: -8px;

        .handle {
            top: 2px;
        }
    }

    &.s {
        bottom: -11px;

        .handle {
            bottom: 3px;
        }
    }

    &:active,
    &:hover {
        .bar,
        .handle {
            opacity: 1;
        }
    }
}
