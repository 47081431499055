@import "scss/lib/index.scss";

.feedback-modal {
    .modal-content {
        min-height: 400px;
    }

    .feedback-modal-inner {
        text-align: center;

        .title {
            @include text-title;
            margin-bottom: 20px;
        }

        .description {
            @include text-body;
        }

        .btn-icon {
            margin-right: 10px;
        }
    }
}
