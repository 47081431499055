@import "scss/lib/index.scss";

.components-tip-overlay {
    &,
    * {
        user-select: none;
        pointer-events: none;
        -webkit-touch-callout: none;
    }

    .tooltip {
        @include text-body;

        padding: 0.8em 1em;
        color: #fff;
        background: rgba(79, 79, 79, 0.8);
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
    }
}
