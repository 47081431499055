@import "scss/lib/index.scss";

.clusters-list-cluster {
    width: 550px;
    padding: 30px;
    display: flex;
    outline: none;

    &:not(.expanded) {
        cursor: pointer;

        &:hover,
        &:focus {
            background: $color-neutral-200;

            .cluster-info {
                .cluster-actions {
                    visibility: visible;
                }
            }
        }
    }

    .cluster-icon {
        margin-right: 30px;
    }

    .cluster-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .cluster-header {
            flex: 1;
            display: flex;

            .cluster-text {
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                .cluster-name {
                    @include text-subheader;
                    margin: 6px 0 4px 0;

                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .cluster-description {
                    @include text-body;
                    color: $color-neutral-700;

                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .cluster-actions {
                margin-top: 6px;
                visibility: hidden;

                .delete-cluster-icon {
                    cursor: pointer;
                    color: $color-neutral-800;
                    transition: none;
                }
            }
        }

        .back-to-clusters-list {
            margin-top: 20px;
        }
    }
}

.error-msg {
    color: $error-text;
    font-size: 17px;
    margin-bottom: 20px;
}

.clusters-list-page {
    .clusters-list-main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .header {
        margin-bottom: 50px;
        text-align: center;

        h1 {
            @include text-title;
            margin-bottom: 20px;
        }

        h2 {
            @include text-body-2;
            color: $color-neutral-700;

            div {
                margin-bottom: 5px;
            }
        }
    }

    .clusters-list {
        .clusters-list-cluster {
            border-bottom: 1px solid $color-neutral-300;

            &:first-child {
                border-top: 1px solid $color-neutral-300;
            }
        }
    }

    .add-new-cluster-btn {
        /* anchor tag */
        color: inherit;

        display: flex;
        width: 100%;
        padding: 30px;
        align-items: center;

        &:hover {
            background: $color-neutral-300;
        }

        .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            color: $color-neutral-500;
            border: 2px solid $color-neutral-500;

            margin-right: 30px;

            min-width: 72px;
            height: 72px;
        }

        .add-text {
            display: flex;
            flex: 1;
            flex-direction: column;

            .title {
                @include text-subheader;
                margin: 6px 0 4px;
            }

            .description {
                @include text-body;
                color: $color-neutral-700;

                margin-top: 8px;
            }
        }
    }

    .clusters-list-footer {
        text-align: center;
        margin-top: 50px;
    }
}
