@import "scss/lib/index.scss";

.schema-page-databases-main {
    height: 100%;
    display: flex;
    flex-direction: column;

    > .title {
        font-weight: 900;
        padding: 20px 10px 20px 20px;
        border-bottom: 1px solid $color-neutral-300;
    }

    .alert-offline-databases {
        margin: 16px 24px;

        .offline-title {
            color: $error-text;
        }

        .database-states-help-link {
            display: block;
            margin-top: 6px;
        }

        + .schema-table {
            border-top: 1px solid $color-neutral-300;
        }
    }

    .schema-table {
        flex: 1;
    }
}
