@import "scss/lib/index.scss";

$toggle-radius: 5px;
$toggle-border: 1px solid $color-neutral-700;

.components-radio-toggle {
    display: flex;
    flex-direction: row;

    border-radius: $toggle-radius;

    .toggle-item {
        flex: 1;

        padding: 10px 20px;
        cursor: pointer;
        outline: none;
        font-weight: bold;
        text-align: center;
        transition: 200ms;

        border-top: $toggle-border;
        border-bottom: $toggle-border;
        border-left: $toggle-border;

        background-color: white;

        &.selected {
            background: $color-purple-900;
            color: white;
        }

        &:first-child {
            border-top-left-radius: $toggle-radius;
            border-bottom-left-radius: $toggle-radius;
        }

        &:last-child {
            border-right: $toggle-border;

            border-top-right-radius: $toggle-radius;
            border-bottom-right-radius: $toggle-radius;
        }
    }
}
