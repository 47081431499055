@import "scss/lib/index.scss";

.sample-data-tab {
    display: flex;
    flex-direction: column;
    height: 100%;

    .sample-data-no-rows {
        text-align: center;
        color: $color-neutral-700;
        margin: 20px 0;
    }
}
