@import "scss/lib/index.scss";

.schema-page-partitions-main {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    > .errors-card {
        margin: 16px 24px;

        .errors-card-title {
            display: flex;
            align-items: center;

            > .partition-status {
                display: inline-block;

                margin-left: 5px;
            }
        }

        .errors-list {
            margin-top: 10px;
        }

        + .partition-info-card {
            margin-top: 0px;
        }
    }

    > .partition-info-card {
        margin: 16px 24px;
        padding: 16px;
    }

    > .zero-partition-instances {
        padding-left: 24px;
    }
}
