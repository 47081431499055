@import "scss/lib/index.scss";

.components-centering-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    overflow-y: auto;
    margin-top: auto;
    margin-bottom: auto;
}
