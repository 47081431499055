@import "scss/lib/index.scss";

.common-breadcrumbs {
    display: flex;
    min-width: 0px;

    > .common-breadcrumb {
        display: flex;
        align-items: center;
        min-width: 0px;

        > .icon {
            margin: 0 10px;
            color: $color-neutral-700;
        }

        > .link {
            color: $color-neutral-700;
            font-size: 16px;
            font-weight: 700;

            display: flex;
            align-items: center;
            min-width: 0px;

            > .tooltip {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            > .subname {
                margin-left: 3px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &:last-child {
            .link {
                color: $color-neutral-900;
            }
        }

        &:not(:last-child) {
            > .link:hover {
                color: $color-neutral-900;
            }
        }
    }
}
