@import "scss/lib/index.scss";

.schema-table-empty {
    height: 100%;

    .icon {
        margin-bottom: 40px;
    }

    .title {
        @include text-title;

        margin-bottom: 20px;
    }

    .description {
        @include text-body-2;
    }
}
