@import "scss/lib/index.scss";

.components-form-element {
    > .checkbox-wrapper {
        user-select: none;
        display: flex;
        align-items: baseline;

        > .content-wrapper {
            > .checkbox-label {
                display: block;
                padding-left: 28px;
                cursor: pointer;
            }

            > .message-wrapper {
                margin-left: 28px;
            }
        }
    }

    > .label-wrapper {
        > label {
            display: block;
            margin-bottom: 8px;
            @include text-label;
            color: $color-neutral-800;
        }
    }

    .message-wrapper {
        margin-top: 5px;
        @include text-caption;
        color: $color-neutral-800;

        .common-icon {
            margin-right: 5px;
        }
    }

    &.touched.invalid .message-wrapper {
        color: $error-text;
    }
}
