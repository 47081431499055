@import "scss/lib/index.scss";

.monitor-page-nodes {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;

    .header-filter .query-text {
        @include text-monospaced;
        font-weight: $font-weight-regular;
    }
}
