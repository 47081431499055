@import "scss/lib/index.scss";

.schema-page-table {
    > .table-info-card {
        margin: 16px 24px;
        padding: 16px;

        .row-count-tip {
            font-size: 14px;
            margin: 0 8px;
        }
    }

    .schema-page-table-main {
        height: 100%;
        display: flex;
        flex-direction: column;

        .schema-table {
            flex: 1;
        }

        .cell {
            .monospaced {
                @include text-monospaced;
            }
        }

        .no-indexes {
            padding: 20px;
        }
    }
}
