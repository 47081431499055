@import "scss/lib/index.scss";

.explain-node {
    width: 100%;
    height: 100%;
    border-radius: 4px;

    &.with-table-scan {
        /* set the background to gray for the table scan but hide the top
         * corners behind the white body by having a larger border radius than
         * the border radius of .body */
        background-color: $color-neutral-700;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .body {
        background-color: $color-neutral-0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 4px;

        .type {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .label {
                font-size: 12px;
                font-weight: bold;
                max-width: 100%;
                text-align: center;
                line-height: 1;
                margin-top: 2px;
            }
        }

        .bars {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;

            color: $color-neutral-500;

            .stat {
                color: $color-neutral-800;
                font-weight: bold;
                line-height: 1.2;
            }
        }
    }

    .table-scan {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color-neutral-0;

        .table-name {
            margin-left: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.small {
        .body {
            padding: 7px;

            .bars {
                padding-left: 4px;
            }
        }
    }

    &.medium {
        .body {
            padding: 10px;

            .bars {
                border-left: 1px solid $color-neutral-500;
                padding-left: 6px;
                margin-left: 6px;

                .stat {
                    font-size: 9px;
                }
            }
        }

        .table-scan {
            padding: 6px;
            line-height: 1;
            font-size: 9px;
        }
    }

    &.large {
        .body {
            padding: 12px;

            .type {
                width: 33.3%;

                overflow-wrap: break-word;

                /* It's okay if the label is a little wider than the width of
                 * this element and overlaps a little of the constant-width
                 * padding all around the body. Because the label is centered
                 * and can wrap, it won't go outside for a lot of nodes, and
                 * when it does we'd usually prefer a slightly long line to two
                 * very short lines. */
                margin-left: -4px;
                margin-right: -4px;

                .label {
                    flex-grow: 1;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            .bars {
                margin-left: 8px;
                border-left: 1px solid $color-neutral-500;
                padding-left: 8px;
                font-size: 14px;

                .stat {
                    font-size: 16px;
                }
            }
        }

        .table-scan {
            padding: 0 10px;
            line-height: 1;
            font-size: 16px;
        }
    }

    &.highlighted {
        box-shadow: 0 0 0 1px $color-purple-600, 0 0 3px 1px $color-purple-600;
    }
}
