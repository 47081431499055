@import "scss/lib/index.scss";

.simple-modal-body {
    display: flex;
    flex-direction: column;
    text-align: center;

    .title {
        @include text-title;
    }

    .description {
        @include text-body;
        margin-top: 20px;
        width: 70%;
        align-self: center;
    }
}
