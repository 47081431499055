@import "scss/lib/index.scss";

.layout-main-sidebar-outer {
    $sidebar-bg: $color-neutral-900;
    $sidebar-section-header-text: rgba(255, 255, 255, 0.5);

    position: relative;
    display: flex;
    flex-direction: column;

    .brand-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        background-color: $sidebar-bg;
        border-bottom: 1px solid $color-neutral-999;
        min-height: 60px;
        padding: 10px 0;

        .brand-header {
            display: flex;
            align-items: center;
            justify-content: center;

            .logo-studio-title {
                margin-left: 8px;
            }
        }
    }

    .layout-main-sidebar {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        background-color: $sidebar-bg;
        box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.29);

        transition: width 0.05s;
        padding-bottom: 10px;

        .title-section {
            flex-shrink: 0;

            .title-icons {
                .circle-bg {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                }

                .icon-logo {
                    background-color: $color-neutral-800;
                    color: $color-neutral-0;
                }
            }
        }

        .layout-main-sidebar-link {
            display: block;
            @include text-body;
            position: relative;
            color: $color-neutral-0;
            cursor: pointer;

            .layout-main-sidebar-ext-link-icon {
                display: none;
                position: absolute;
                right: 20px;
            }

            &.active {
                background-color: $sidebar-active-bg;
            }

            &:hover:not(.active) {
                background-color: $sidebar-hover-bg;
            }
        }

        .layout-ext-link-tip {
            display: flex;
            align-items: center;
        }

        .cluster-dropdown-wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
            outline: none;
            cursor: pointer;
        }

        .sidebar-footer {
            margin: auto 0 0 5px;
        }

        $icon-logo-height: 72px;

        &.wide {
            $padding-side: 30px;

            .title-section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 24px 0 15px 0;

                .title-icons {
                    position: relative;

                    // We fix the height of this div to avoid a weird bug where
                    // the title-icons div would actually be larger than its
                    // children since inline-block divs add an invisible margin
                    // around them.
                    height: $icon-logo-height;

                    .icon-logo {
                        width: 72px;
                        height: $icon-logo-height;
                    }

                    .icon-tag {
                        position: absolute;
                        right: -20px;
                        bottom: -5px;
                    }
                }

                .title-wrapper {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 15px;
                    width: calc(100% - 20px);
                    padding: 5px;
                    border-radius: 4px;
                    color: $color-neutral-0;

                    .title-row {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: calc(100% - 30px);

                        .title {
                            @include text-subheader-small;
                            text-align: center;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        > .title-menu-icon {
                            margin-left: 10px;
                        }
                    }

                    .subtitle {
                        color: $sidebar-section-header-text;
                        font-size: 12px;
                        text-align: center;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &:hover {
                        background-color: $sidebar-hover-bg;

                        .subtitle {
                            color: $color-neutral-0;
                        }
                    }
                }
            }

            .layout-main-sidebar-section {
                margin-bottom: 24px;

                .section-title {
                    @include text-label;
                    font-size: 12px;
                    color: $sidebar-section-header-text;

                    text-transform: uppercase;
                    padding: 0 $padding-side;
                    margin-bottom: 7px;
                }

                &:last-of-type {
                    padding-bottom: 10px;
                }
            }

            .layout-main-sidebar-link {
                line-height: 160%;
                padding: 3px $padding-side;

                .link-icon {
                    display: inline-block;
                    margin-right: 5px;
                }

                .link-name {
                    display: inline-block;
                }

                &:hover {
                    .layout-main-sidebar-ext-link-icon {
                        display: inline-block;
                    }
                }
            }
        }

        &.narrow {
            .title-section {
                padding: 24px 0 15px 0;
                display: flex;
                align-items: center;
                flex-direction: column;

                .title-icons {
                    .icon-logo,
                    .icon-tag {
                        width: 36px;
                        height: 36px;
                    }

                    .icon-logo {
                        margin-bottom: 15px;
                        img {
                            width: 20px;
                        }
                    }
                }

                .title-menu-icon,
                .subtitle {
                    display: none;
                }
            }

            .layout-main-sidebar-section {
                .section-title {
                    display: none;
                }

                &:last-of-type {
                    padding-bottom: 20px;
                }
            }

            .layout-main-sidebar-link {
                padding: 0 11px;
                font-size: 20px;

                .link-icon {
                    padding: 12.5px;
                    border-radius: 2px;
                }

                .link-name {
                    display: none;
                }
            }

            .sidebar-footer {
                margin: auto 11px 0 11px;
            }
        }
    }
}

.license-tooltip-title {
    font-weight: 700;
}
