@import "scss/lib/index.scss";

.tutorial-container {
    position: absolute;
    right: 0;
    z-index: $zindex-tutorial;

    top: 50%;
    transform: translateY(-50%);
    height: calc(100% - 120px);

    padding-bottom: 10px;

    .tutorial-container-inner {
        display: flex;
        flex-direction: column;

        position: relative;
        height: 100%;
        width: $tutorial-container-width;
        transition: width 0.5s ease-out;

        border-radius: 4px 0 0 4px;
        border: 1px solid $color-neutral-300;
        border-right: none;

        box-shadow: $box-shadow-medium;
        background-color: $color-neutral-0;

        &.minimized {
            width: 0;
        }

        .tutorial-content-wrapper {
            min-width: $tutorial-container-width;
            max-width: $tutorial-container-width;
            max-height: calc(100% - 120px);
            flex: 1;

            &.error {
                display: flex;
            }
        }

        .close-button-wrapper {
            height: 28px;
            width: $tutorial-container-width;
            flex: none;

            display: flex;
            justify-content: flex-end;

            margin: 4px 0;
            border-radius: 50%;

            > .close-button {
                padding: 4px 10px;
                margin: 0 4px;
                cursor: pointer;

                font-size: 20px;
                line-height: 20px;
                color: $color-neutral-700;
            }
        }
    }

    .tutorial-container-toggle {
        z-index: $zindex-tutorial + 1;
        position: absolute;
        left: -22px;
        top: 50%;
        transform: translateY(-50%);

        padding: 10px 6px;
        border-radius: 4px 0 0 4px;
        border: 1px solid $color-neutral-300;
        border-right: none;

        background-color: $color-neutral-0;
        box-shadow: -2px 2px 2px rgba($color-neutral-900, 0.2);

        &:hover,
        &:focus {
            border-right: none;
            box-shadow: -2px 2px 2px rgba($color-neutral-900, 0.2);
        }
    }
}
