@import "scss/lib/index.scss";

.explain-layout-renderer {
    .node-wrapper {
        position: absolute;
    }

    .bracket {
        position: absolute;
        background-color: $color-neutral-700;
    }
}
