@import "scss/lib/index.scss";

.tutorial-menu {
    .tutorial-menu-item {
        cursor: pointer;
        padding: 16px 8px;
        border-bottom: 1px solid $color-neutral-300;

        &:first-of-type {
            // This counteracts the spacing from the mdx content.
            margin-top: -1 * $tutorial-bottom-spacing;
        }

        > .tutorial-item-header {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            color: $color-purple-900;

            > .title {
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
            }
        }

        > .description {
            @include text-caption;
            color: $color-text-3;
            margin-left: 19px;
        }
    }
}
