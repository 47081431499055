@import "scss/lib/index.scss";

@mixin rounded-to-height($height) {
    height: $height;

    > .entry {
        height: 100%;
    }

    &:not(.squared-corners) {
        $border-radius: $height / 2;
        border-radius: $border-radius;

        > .entry {
            &:first-child {
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
            }

            &:last-child {
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }
    }
}

.components-inline-bar {
    width: 100%;
    display: flex;

    /* See https://stackoverflow.com/a/49135602/996056 */
    overflow: hidden;

    @include rounded-to-height(10px);

    &.small {
        @include rounded-to-height(6px);
    }

    &.extra-small {
        @include rounded-to-height(4px);
    }

    .entry {
        transition: 300ms;
    }
}
