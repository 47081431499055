@import "scss/lib/index.scss";

.components-table-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 16px;

    font-size: 14px;
    font-weight: 700;
    color: $color-neutral-900;

    background-color: $color-neutral-100;
    box-shadow: 0px -1px 0px rgba(196, 196, 196, 0.75),
        inset 0px -1px 0px rgba(196, 196, 196, 0.75);
}
