@import "scss/lib/index.scss";

.components-super-table {
    width: 100%;
    height: 100%;
    position: relative;

    overflow: hidden;

    > .error-scrollbars {
        > .error-view {
            display: flex;
            align-items: center;
        }
    }

    &.hidden {
        visibility: hidden;
    }

    .table-inner {
        outline: none; /* suppress outline from tabIndex */

        .compact {
            &.columns {
                .column {
                    .titles-container {
                        .title {
                            font-size: 13px;
                            font-weight: 700;
                            color: $color-neutral-800;
                        }

                        .subtitle {
                            font-size: 12px;
                        }
                    }
                }
            }

            &.rows {
                .super-row {
                    > .cell {
                        padding: 16px;

                        font-size: 13px;
                        line-height: 20px;
                    }
                }
            }
        }

        .columns {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 10;

            background-color: $color-neutral-0;
            border-bottom: 1px solid rgba(19, 27, 67, 0.3);
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);

            .column {
                height: 100%;
                position: absolute;
                top: 0;

                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px;
                min-width: 0;

                &.sortable {
                    cursor: pointer;

                    .icon-sort {
                        margin-left: 2px;
                    }

                    &:hover {
                        background-color: $color-indigo-100;
                    }
                }

                &.measuring {
                    /* When measuring, put the column in static flow so that
                     * its width forces the table-inner element to be at least
                     * as wide as itself. */
                    position: relative;
                }

                .titles-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    min-width: 0;
                    width: 100%;

                    .title {
                        @include text-table-header;
                        color: $color-neutral-900;
                        line-height: inherit;

                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .subtitle {
                        color: $color-neutral-700;
                        font-size: 13px;

                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .resize-handle {
                $bar-width: 3px;

                position: absolute;
                top: 0;
                cursor: ew-resize;
                z-index: $zindex-overlay;
                height: 100%;

                &.last {
                    .bar {
                        // For the last resize handler on the right-most edge of
                        // the viewport - we want the blue bar to be
                        // fully visible and not have it cut off by the right
                        // viewport edge. This moves the bar inwards so the full
                        // width of the bar is visible on hover.
                        right: 0;
                    }
                }

                .bar {
                    pointer-events: none;
                    height: 100%;
                    transition: opacity 0.3s;

                    width: 1px;
                    position: absolute;
                    background-color: $color-neutral-300;
                }

                &.resizing,
                &:hover {
                    z-index: $zindex-overlay + 1;

                    .bar {
                        width: $bar-width;
                        background-color: $color-purple-600;
                    }
                }
            }
        }

        .rows {
            position: relative;

            .super-row {
                position: relative;
                border-bottom: 1px solid $color-neutral-300;

                > .cell:not(:last-child) {
                    box-shadow: 1px 0 0 $color-neutral-200;
                }

                &.striped {
                    background-color: $color-neutral-100;
                }

                &.selected {
                    background-color: $color-indigo-200;
                }

                &.clickable {
                    cursor: pointer;

                    &:hover {
                        background-color: $color-indigo-100;
                    }
                }

                > .cell {
                    height: 100%;
                    position: absolute;
                    padding: 10px 16px;

                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    color: $color-neutral-800;
                    font-size: 14px;

                    .non-selectable-whitespace {
                        user-select: none;
                        content: " ";
                    }

                    &.reveal-on-hover {
                        opacity: 0;
                        transition: 100ms;
                    }

                    &.vertical-align {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    &.measuring {
                        /* When measuring, put the cell in static flow so that
                         * its width forces the table-inner element to be at
                         * least as wide as itself. */
                        position: relative;
                    }
                }

                &:hover {
                    .reveal-on-hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.offscreen {
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: hidden;
}
