@import "scss/lib/index.scss";

.components-top-content-header {
    color: $color-text-1;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;

    &:not(:first-of-type) {
        border-top: 1px solid $color-neutral-300;
        padding-top: 16px;
        margin-top: 20px;
    }
}

.components-top-content-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    grid-column-gap: 20px;
}

.components-top-content {
    $border-left-width: 5px;
    $padding-left-width: 10px;

    > .title {
        display: flex;
        align-items: center;

        @include text-label;
        color: $color-neutral-800;
        border-left: $border-left-width solid $color-neutral-300;
        padding-left: $padding-left-width;
        margin: 20px 0 8px 0;
    }

    > .inner {
        display: flex;
        align-items: center;
        padding-left: $border-left-width + $padding-left-width;
        font-size: 14px;
    }
}
