@import "scss/lib/index.scss";

.tutorial-content {
    display: flex;
    flex-direction: column;

    min-width: $tutorial-container-width;
    max-width: $tutorial-container-width;
    padding: 0 $tutorial-content-padding;

    // This adds some bottom spacing to the MDX content
    > div:not([class]) {
        margin-bottom: $tutorial-bottom-spacing;
    }

    h5,
    h6 {
        margin: 0;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        color: $color-neutral-800;
        margin: 8px 0;
    }

    h3 {
        color: $color-neutral-900;
    }

    h4 {
        font-size: 14px;
        font-weight: bold;
        color: $color-neutral-900;
    }

    h5 {
        @include text-label;
        color: $color-neutral-800;
    }

    h6 {
        @include text-caption;
        color: $color-neutral-700;
    }

    hr {
        margin: 16px (-1 * $tutorial-content-padding);
        border: none;
        border-top: 1px solid $color-neutral-300;
    }

    ul {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 24px;
        font-size: 14px;
    }

    pre {
        background-color: $color-neutral-900;
        border-radius: 4px;
        padding: 8px;

        code {
            color: $color-neutral-0;
            font-size: 16px;
        }
    }

    > .title {
        position: sticky;
        top: 0;

        font-weight: bold;
        color: $color-neutral-900;
        background-color: $color-neutral-0;

        // we use this to prevent buttons from peeking through
        z-index: 1;
    }
}
