@import "scss/lib/index.scss";

.active-process-tooltip {
    @include text-monospaced;
}

.active-process-query {
    display: flex;
    align-items: center;

    &:hover {
        > .button {
            display: block;
        }
    }

    > .query {
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    > .button {
        display: none;
    }
}

.active-process-submitted {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
