@import "scss/lib/index.scss";

.components-checkbox {
    display: inline-block;
    margin-right: -18px;
    cursor: pointer;

    > .checkbox-hidden {
        position: absolute;
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;

        border: 0;
        clip: rect(0, 0, 0, 0);
        clip-path: inset(50%);

        overflow: hidden;
        white-space: nowrap;

        &:checked {
            + .checkbox-styled {
                background-color: $color-purple-900;
                border: 1px solid $color-purple-900;

                > .check-icon {
                    visibility: visible;
                    color: $color-neutral-0;
                }
            }
        }

        &:disabled {
            + .checkbox-styled {
                opacity: 0.25;
            }
        }
    }

    > .checkbox-styled {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 16px;
        height: 16px;
        background-color: $color-neutral-100;
        transition: background-color 0.3s;

        border: 1px solid $color-neutral-700;
        border-radius: 2px;

        > .check-icon {
            visibility: hidden;
        }
    }
}
