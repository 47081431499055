@import "scss/lib/index.scss";

.tab-connection {
    font-size: 17px;
    line-height: 150%;

    display: flex;
    flex-direction: row;

    .section {
        flex-basis: auto;
        padding: 10px;

        .section-title {
            font-weight: bold;
            margin-bottom: 10px;
        }

        .online {
            color: $success-text;
        }
        .offline {
            color: $error-text;
        }

        .info-div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
