@import "scss/lib/index.scss";

.tutorial-progress-bar {
    min-height: 6px;
}

.tutorial-stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 16px $tutorial-content-padding;
    position: relative;

    > .back-button {
        position: absolute;
        left: 0;
    }

    > .label {
        margin: 0 auto;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: $color-text-2;

        &.hidden {
            visibility: hidden;
        }
    }

    > .next-button {
        position: absolute;
        right: 0;
    }
}
