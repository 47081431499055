@import "scss/lib/index.scss";

.components-feature-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    max-width: 800px;
    min-height: 500px;

    padding: 0;
    text-align: center;

    margin: 15px;
    padding: 50px 100px;
    overflow: hidden;

    .feature {
        margin-bottom: 50px;
    }

    .title {
        @include text-title;
        margin-bottom: 20px;
        color: $color-neutral-900;
    }

    .body {
        @include text-body-2;
        max-width: 600px;
        margin-bottom: 30px;
        color: $color-neutral-700;
    }

    &.compact {
        .feature {
            margin-bottom: 30px;
        }

        .title {
            @include text-subheader;
            margin-bottom: 10px;
            color: $color-neutral-900;
        }

        .body {
            @include text-body;
            max-width: 400px;
            margin-bottom: 20px;
            color: $color-neutral-700;
        }
    }
}
