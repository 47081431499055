@import "scss/lib/index.scss";

.explain-section {
    // this needs to match with sidebar.scss
    $hor-padding: 15px;
    $ver-padding: 15px;
    padding: $ver-padding $hor-padding;

    &:not(:first-child) {
        border-top: 1px solid $color-neutral-300;
    }

    .explain-section-title {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 18px;

        .icon-expand {
            margin-right: 10px;
            width: 15px;
            transition: 200ms;

            &.expanded {
                transform: rotate(90deg);
            }
        }
    }

    .explain-section-content {
        margin-top: $ver-padding;

        .section-row {
            display: flex;
            margin-bottom: $ver-padding;
        }

        .section-element {
            flex: 50%;

            .section-label {
                color: $color-neutral-800;
                font-size: 12px;
            }

            .section-value {
                color: $color-neutral-999;
                font-size: 16px;
            }
        }
    }
}
