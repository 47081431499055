@import "scss/lib/index.scss";

.dashboard-page {
    @include full-page;

    .cards {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;
        overflow-y: auto;

        $margin: 30px;

        @include breakpoint(zero, small) {
            @include n-columns(1, $margin);
        }

        @include breakpoint(small) {
            @include n-columns(2, $margin);
        }

        > .card {
            max-width: 580px;
            min-height: 370px;
        }
    }
}
