@import "scss/lib/index.scss";

.node-state-cell {
    height: 100%;
    display: flex;
    align-items: center;

    &.error {
        color: $error-text;
    }
}

.node-address-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    line-height: 20px;
    max-height: 40px;
}
