@import "scss/lib/index.scss";

.mv-name-cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mv-tooltip-query {
    margin-top: 10px;
}

.mv-tooltip-code {
    @include text-monospaced;
}
