@import "scss/lib/index.scss";

.tutorial-breadcrumbs {
    font-size: 14px;
    line-height: 20px;
    color: $color-text-3;

    margin-bottom: 6px;
    padding: 0 $tutorial-content-padding;

    > .breadcrumb {
        cursor: pointer;
    }
}
