@import "scss/lib/index.scss";

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.layout-main-bottom-panel {
    position: relative;
    flex: 0 0 auto;
    z-index: $zindex-bottom-panel;

    display: flex;
    flex-direction: column;
    background: $color-neutral-900;
    color: $color-neutral-0;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05), 0px -1px 0px #e6e6e6;
    overflow: hidden;

    min-height: 0;
    height: 0;

    transition: min-height 100ms;

    &.resizing {
        .top-bar {
            border-top: 2px solid $color-purple-600;
        }
    }

    .panel-inner-wrap {
        height: 100%;
        overflow: hidden;
    }

    .top-bar {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 2px;

        background: $color-neutral-0;
        opacity: 1;
        cursor: ns-resize;

        .top-bar-content {
            display: flex;
            align-items: center;
        }

        .resize-buttons {
            a {
                animation: fadeIn 200ms;
            }

            padding: 6px;
            height: 32px;
        }
    }

    &.minimized {
        box-shadow: none;
        border-top: 1px solid $color-neutral-300;

        .top-bar {
            background: $color-neutral-100;
        }
    }

    .panel-content {
        flex: 1;
        display: flex;
        overflow: auto;
        border-left: 1px solid $color-neutral-800;
    }

    .minimized-maximize-button {
        color: $color-neutral-700;
    }
}
