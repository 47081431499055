@import "scss/lib/index.scss";

$fullscreen-content-width-large: 40%;
$fullscreen-content-width-normal: 60%;

$fullscreen-content-padding-top: 100px;

$fade-in-animation-length: 150ms;
$fade-in-animation-timing-function: ease-in-out;

@keyframes fullscreen-content-fade-in {
    from {
        padding-top: $fullscreen-content-padding-top + 75px;
    }
    to {
        padding-top: $fullscreen-content-padding-top;
    }
}

@keyframes fullscreen-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.components-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#333333, 0.9);

    display: flex;

    animation-name: fullscreen-fade-in;
    animation-duration: $fade-in-animation-length;
    animation-timing-function: $fade-in-animation-timing-function;

    .fullscreen-inner {
        position: relative;
        background-color: white;
        box-sizing: border-box;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
    }

    .fullscreen-scroll {
        flex: 1;
    }

    .fullscreen-content {
        min-height: 100%;
        display: flex;
        justify-content: center;

        .fullscreen-content-inner {
            padding-top: $fullscreen-content-padding-top;
            padding-bottom: 50px;

            animation-name: fullscreen-content-fade-in;
            animation-duration: $fade-in-animation-length;
            animation-timing-function: $fade-in-animation-timing-function;

            width: $fullscreen-content-width-normal;

            @include breakpoint(xlarge) {
                width: $fullscreen-content-width-large;
            }
        }
    }

    .fullscreen-actions {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        display: flex;
        justify-content: center;

        box-shadow: 0px 0px 2px 0px rgba(168, 168, 168, 1);

        .fullscreen-actions-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: $fullscreen-content-width-normal;

            @include breakpoint(xlarge) {
                width: $fullscreen-content-width-large;
            }
        }
    }
}
