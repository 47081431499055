@import "scss/lib/index.scss";

.editor-page-editor {
    @include full-page;

    .panes {
        width: 100%;
        height: 100%;
        display: flex;

        .editor-pane {
            height: 100%;

            display: flex;
            flex-direction: column;
            flex: 1;

            .editor-content {
                display: flex;
                flex-direction: column;
                flex: 1;
                overflow-y: hidden;

                .buffer-container {
                    .react-monaco-editor-container {
                        overflow: hidden;
                        min-height: 50%;
                    }
                }
            }
        }

        .schema-tree-pane {
            border-left: 1px solid $color-neutral-300;
        }
    }
}

.query-tip,
.error-tip {
    white-space: pre-wrap;
    margin: 0;
}
