@import "scss/lib/index.scss";

.components-select {
    width: 100%;
    font-size: 17px;
    line-height: 20px;

    box-sizing: border-box;
    border-radius: 4px;
    border: 2px solid transparent;
    padding: 8px;
    outline: none;

    appearance: none;
    background: url("~assets/images/select-arrow.svg") no-repeat;
    background-size: 12px;
    background-position: calc(100% - 8px) center;
    background-repeat: no-repeat;
    background-color: rgba(189, 189, 189, 0.13);

    &:focus,
    &:active {
        background-color: white;
        border-color: $color-purple-600;
    }

    &.touched {
        &.valid {
            border-color: $success-text;
        }

        &.invalid {
            border-color: $error-text;
        }
    }
}
