.forms-mv-record {
    > .fields {
        display: flex;
        flex-direction: column;
        justify-content: center;

        margin-top: 40px;

        .choose-duration {
            margin-bottom: 20px;

            .custom-duration {
                display: flex;

                .interval-input {
                    flex-grow: 2;
                    margin-bottom: 0;
                }

                .duration-unit {
                    flex-grow: 1;
                    margin-left: 10px;
                }
            }
        }
    }
}
