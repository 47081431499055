@import "scss/lib/index.scss";

.mv-time-breakdown-column {
    width: 100%;
    height: 10px;
    display: flex;
    align-items: center;

    .components-overlay {
        height: 100%;
        width: 100%;
    }
}

.time-breakdown-tooltip {
    min-width: 300px;

    .tip-line {
        display: flex;

        .stat-color {
            border-radius: 100%;
            width: 12px;
            height: 12px;
            margin-top: 5px;
            margin-right: 8px;
        }

        .stat-title {
            flex: 1;
        }

        .stat-label {
            font-weight: bold;
        }
    }
}
