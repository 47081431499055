@import "scss/lib/index.scss";

.output-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    .query-statuses {
        height: 100%;
        display: flex;
        align-items: center;
        white-space: nowrap;

        .query-status {
            flex: none;

            margin-right: 15px;
            white-space: nowrap;
            font-size: 13px;

            &.loading {
                display: flex;
                align-items: center;
            }

            &.success {
                color: $success-text;
                font-weight: bold;
            }

            &.error {
                color: $error-text;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }

    .editor-tab-bar {
        background-color: $color-neutral-100;

        .tab-title-name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .resize-buttons {
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 5px;
        }
    }

    .table-wrapper {
        width: 100%;
        flex: 1;

        font-size: 14px;

        .query-cell {
            display: flex;
            overflow: hidden;

            > .status {
                flex: none;

                > .success {
                    color: $success-text;
                }

                > .error {
                    color: $error-text;
                }
            }

            > .texts {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                overflow: hidden;

                > .query-text {
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    @include text-monospaced;
                }

                > .error-text {
                    margin-top: 8px;
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: $error-text;
                }
            }
        }

        &.closed {
            display: none;
        }

        .column-header {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            /* https://stackoverflow.com/a/20566810 */
            vertical-align: bottom;
        }
    }

    .results-loading-description {
        font-weight: bold;
        margin-top: 15px;
    }

    .results-none {
        padding: 15px;
    }
}
