@import "scss/lib/index.scss";

.components-menu {
    padding: 10px 0;

    .tip-overlay {
        display: block;
    }

    .components-menu-item {
        box-sizing: border-box;
        padding: 10px 20px;
        color: $color-neutral-800;
        font-size: 15px;
        white-space: nowrap;
        display: block;

        &:not(.disabled) {
            cursor: pointer;
        }

        &:hover:not(.disabled),
        &:focus:not(.disabled) {
            background: $color-indigo-100;

            // this disables styling from typography.scss, which underlines all
            // anchor links on hover and focus
            text-decoration: none;
        }

        &.disabled {
            cursor: not-allowed;
            color: $color-neutral-500;
        }
    }

    &.small {
        border-radius: 3px;
        padding: 5px 0;

        .components-menu-item {
            padding: 5px 10px;
            font-size: 12px;
        }
    }

    /* https://stackoverflow.com/a/6382036 */
    .components-menu-separator {
        width: 80%;

        height: 1px;
        border: 0;
        border-top: 1px solid $color-neutral-500;
    }
}
