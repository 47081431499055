@import "scss/lib/index.scss";

.components-circle-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    // default colors
    background: $color-neutral-200;
    color: $color-neutral-700;

    // sizes
    &.small {
        width: 40px;
        height: 40px;
        font-size: 20px;

        .status-icon {
            position: absolute;
            font-size: 16px;
            line-height: 100%;
            right: -6px;
            bottom: 2px;
        }

        .loading-outline svg {
            width: 40px;
            height: 40px;
        }
    }

    &.medium {
        width: 80px;
        height: 80px;

        .status-icon {
            position: absolute;
            font-size: 32px;
            line-height: 100%;
            right: -6px;
            bottom: 2px;
        }

        .loading-outline svg {
            width: 80px;
            height: 80px;
        }
    }

    &.large {
        width: 120px;
        height: 120px;

        .status-icon {
            position: absolute;
            font-size: 40px;
            line-height: 100%;
            right: -12px;
            bottom: 2px;
        }

        .loading-outline svg {
            width: 120px;
            height: 120px;
        }
    }

    &.colored-background {
        background-color: $color-purple-200;
    }

    .loading-outline {
        position: absolute;
        left: 0;
        top: 0;
    }
}
