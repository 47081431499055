@import "scss/lib/index.scss";

.current-version-details {
    margin-left: 15px;

    .current-version-heading {
        color: $color-neutral-700;
        font-weight: bold;
    }

    .current-version-number {
        color: $color-neutral-800;
        font-weight: bold;
    }
}

.latest-version {
    display: flex;
    align-items: flex-start;

    margin-top: 10px;
    padding: 10px;

    > .content {
        color: $color-neutral-700;

        > .heading {
            font-weight: bold;

            margin-bottom: 5px;
        }
    }

    .latest-version-details {
        flex: 1;
        margin-left: 12px;
        font-size: 14px;
        line-height: 1.583;

        .latest-version-number {
            color: $color-neutral-800;
            font-weight: bold;
        }

        .latest-version-notes {
            color: $color-neutral-700;
            margin-top: 5px;
        }

        .latest-version-link {
            margin-top: 5px;
        }
    }
}
