@import "scss/lib/index.scss";

.about-page {
    @include full-page;

    > .about-header {
        .last-updated {
            color: $color-neutral-700;
            margin-right: 15px;
        }
    }

    .cards {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: flex-start;
        overflow-y: auto;

        $margin: 30px;

        @include breakpoint(zero, small) {
            @include n-columns(1, $margin);
        }

        @include breakpoint(small) {
            @include n-columns(2, $margin);
        }
    }

    .license-card-content {
        font-size: 14px;

        .license-highlight-details {
            margin-left: 12px;

            .license-heading {
                color: $color-neutral-700;
                font-weight: bold;
            }

            .license-type {
                color: $color-neutral-800;
                font-weight: bold;
            }
        }

        .license-icon {
            color: $color-neutral-700;
        }

        .license-details {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 14px;

            .license-detail {
                display: flex;
                color: $color-neutral-700;
                margin-top: 5px;

                .left {
                    min-width: 20%;
                    font-weight: bold;
                }

                .right {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
