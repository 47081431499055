@import "scss/lib/index.scss";

.info-card {
    max-width: 580px;
    min-height: 370px;

    overflow-y: auto;
    padding: 20px;

    font-size: 12px;
    color: $color-neutral-700;

    box-shadow: none;

    position: relative;

    @mixin title {
        font-size: 18px;
        text-align: center;
        font-weight: 900;
        color: $color-neutral-800;
    }

    @mixin description {
        font-size: 15px;
        text-align: center;
        color: $color-neutral-700;
    }

    .card-title {
        @include title;
    }

    .card-description {
        @include description;
        margin-top: 5px;
    }

    .card-help-link {
        transition: opacity 200ms linear;
        visibility: hidden;
        opacity: 0;

        position: absolute;
        top: 15px;
        right: 20px;
    }

    &:hover {
        .card-help-link {
            visibility: visible;
            opacity: 1;
        }
    }

    &.success {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .top-container {
            > .content {
                margin-top: 25px;

                .highlight {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    border-radius: 6px;
                    border: 1px solid $color-neutral-300;
                    font-size: 14px;
                    padding: 14px;
                }
            }
        }

        > .view-all {
            transition: opacity 200ms linear;
            visibility: hidden;
            opacity: 0;
        }

        &:hover {
            > .view-all {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &.loading {
        text-align: center;
        font-size: 17px;

        display: flex;
        flex-direction: column;

        .loading-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: 700;

            .components-loading {
                margin-bottom: 10px;
            }
        }
    }

    &.empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > .empty-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            > .title {
                @include title;
                margin-top: 10px;
            }

            > .description {
                @include description;
                margin-top: 5px;
            }

            > .help-link {
                margin-top: 5px;
                font-size: 15px;
            }
        }
    }

    &.error {
        > .error-container {
            margin-top: 40px;
            min-height: 65%;
            border: 1px solid $color-neutral-300;
            border-radius: 6px;
            text-align: center;
            padding: 20px;

            > .icon {
                color: $color-neutral-700;
            }

            > .title {
                margin-top: 20px;

                color: $color-neutral-800;
                font-weight: 700;
                font-size: 20px;
            }

            > .message-container {
                margin-top: 20px;
                color: $color-neutral-700;
                font-size: 14px;
                line-height: 150%;

                > .message {
                    @include text-monospaced;
                    font-size: 13px;
                    margin-top: 10px;
                }
            }
        }
    }
}
