@import "scss/lib/index.scss";

.status-cell {
    height: 100%;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.error {
        color: $error-text;
    }
}
