@import "scss/lib/index.scss";

.explain-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .components-icon {
        color: $color-indigo-600;
    }

    /* Unknown executor types are displayed as two white letters against a solid colored circle. */
    &.unknown {
        border-radius: 100%;
        background-color: $color-indigo-600;
        color: $color-neutral-0;
    }

    &.small {
        width: 16px;
        height: 16px;

        .components-icon {
            font-size: 15px;
        }

        &.unknown {
            font-size: 9px;
        }
    }

    &.medium {
        width: 40px;
        height: 40px;

        .components-icon {
            font-size: 32px;
        }

        &.unknown {
            font-size: 22px;
        }
    }

    &.large {
        width: 38px;
        height: 38px;

        .components-icon {
            width: 25px;
            height: 38px;

            font-size: 25px;
        }

        &.unknown {
            font-size: 18px;
        }
    }
}
