.components-form {
    display: flex;
    flex-direction: column;

    .components-form-element:not(:last-child) {
        margin-bottom: 20px;
    }

    .hidden-submit-button {
        position: absolute;
        left: -9999px;
    }
}
