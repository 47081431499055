@import "scss/lib/index.scss";

.bottom-panel-tab-switcher {
    flex: 1 1;
    display: flex;
    align-self: stretch;

    padding-left: 10px;

    .tab-title {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 10px;

        cursor: pointer;
        color: $color-neutral-800;
        outline: none;
        font-size: 15px;

        transition: border-bottom 150ms;
        border-bottom: 2px solid transparent;

        &.active {
            border-bottom-color: $color-purple-900;
            color: $color-neutral-900;
        }

        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}
