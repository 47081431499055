@import "scss/lib/index.scss";

.database-usage-card-content {
    .top-databases-view {
        margin-top: 10px;

        .db-name {
            @include text-monospaced;
            font-size: 14px;
        }
    }
}
