@import "scss/lib/index.scss";

.entity-name-icon {
    margin-right: 5px;
}

.size-circle-cell {
    height: 100%;
    display: flex;
    align-items: center;

    > .circle-container {
        width: 20px;
        margin-right: 12px;

        /* We are doing this so that the circle size is centered. */
        .components-size-circle {
            display: block;
            margin: auto;
        }
    }

    > .text-container {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
