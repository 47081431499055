.name-icon {
    margin-right: 5px;
}

.pipeline-state {
    display: flex;
    align-items: center;

    > .icon {
        margin-right: 5px;
    }
}
