@import "scss/lib/index.scss";

.topology-page {
    @include full-page;

    .last-updated {
        color: $color-neutral-700;
        margin-right: 15px;
    }
}
