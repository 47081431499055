@import "scss/lib/index.scss";

.components-labeled-inline-bar {
    display: flex;
    align-items: center;

    > .label {
        color: $color-neutral-900;
        margin-left: 15px;
    }

    // label above the bar
    &.top {
        flex-direction: column-reverse;
        align-items: flex-start;

        > .label {
            margin-left: 0px;
            margin-bottom: 4px;
        }
    }
}
