@import "scss/lib/index.scss";

.query-group-modal-body {
    display: flex;
    flex-direction: column;

    .title {
        @include text-title;
        text-align: center;
    }

    .description {
        @include text-body;
        margin-top: 10px;
        text-align: center;
    }

    .query-error {
        @include text-body;
        margin-top: 30px;
        color: $error-text;
    }

    .query-text {
        margin-top: 10px;
    }
}
